import axios from '@/libs/axios'

class DataService {
  getAll(page) {
    return axios.get("/users?accountType=1&page=" + page);
  }
  get(id) {
    return axios.get(`/admin/${id}`);
  }
  create(data) {
    return axios.post("/providers", data);
  }
  update(id, data) {
    return axios.put(`/admin/${id}`, data);
  }
  delete(id) {
    return axios.delete(`/admin/${id}`);
  }
  changePassword(id) {
    return axios.get(`/changePassword/${id}`);
  }
  getUserCount() {
    // return axios.get(`/users/count?accountType=2`);
    return axios.get("/users?accountType=1&page=1");

  }

  reviewData(providerId,page) {
    return axios.get(`/reviews?providerId=${providerId}&page=${page}`);
  }

  deleteReview(id) {
    return axios.delete(`/reviews?id=${id}`);
  }
  
}
export default new DataService();